class NavigationMobileAccordion {
  /**
   * @param {Element} element
   */
  constructor(element){
    this.toggleButton = element.querySelector('.js-nav-mobile-accordion__toggle');
    this.toggleButton.addEventListener('click', this.toggle.bind(this), false);
    this.link = element.querySelector('.js-nav-mobile-accordion__link');
    this.content = element.querySelector('.js-nav-mobile-accordion__content');
    this.iconWrapper = element.querySelector('.js-nav-mobile-accordion__icon');
    this.icon = this.iconWrapper.firstElementChild;
    this.isOpen = false;
  }

  toggle(){
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.link.classList.add('navigation__mobile-link--open');
      this.content.classList.add('navigation__accordion-content--open');
      this.iconWrapper.classList.remove('navigation__accordion-icon--hidden');
      this.icon.classList.add('icon--chevron-up');
      this.icon.classList.remove('icon--chevron-down');
    } else {
      this.link.classList.remove('navigation__mobile-link--open');
      this.content.classList.remove('navigation__accordion-content--open');
      this.iconWrapper.classList.add('navigation__accordion-icon--hidden');
      this.icon.classList.remove('icon--chevron-up');
      this.icon.classList.add('icon--chevron-down');
    }
  }
}

const mobileAccordions = document.querySelectorAll('.js-nav-mobile-accordion');

if (mobileAccordions.length) {
  mobileAccordions.forEach(accordion => {
    new NavigationMobileAccordion(accordion);
  });
}
