(function($) {
  $(document).ready(function() {

    /**
      * Oppaat page's sticky sidebar handler
      */

    if (document.body.classList.contains('post-template-template-oppaat')) {
      var contentTop = $('.single-post__content').offset().top;
      var contentHeight = $('.single-post__content').outerHeight();
      var windowHeight = $(window).height();
      var documentHeight = $(document).height();
      var sidebarHeight = $('.single-post__sidebar__bg').outerHeight();
      var sidebarTopAmount = 64;
      var webfontsLoaded = false;
    }

    function sidebarHandler() {
      //var scrollTop = $(window).scrollTop();
      var scrollTop = window.scrollY;
      var sidebarTop = $('.single-post__sidebar__bg').offset().top;
      if ((sidebarTop - scrollTop) >= 64 && (sidebarTop - scrollTop) < 128) {
        sidebarTopAmount = 64;
      } else if (sidebarTop - scrollTop === 128) {
        sidebarTopAmount = 128;
      }

      if (webfontsLoaded === false) {
        contentHeight = $('.single-post__content').outerHeight();
        documentHeight = $(document).height();

        if ($('html').hasClass('wf-active')) {
          webfontsLoaded = true;
        }
      }

      if (sidebarHeight + sidebarTopAmount + scrollTop > contentHeight + contentTop) {
        document.body.classList.add('sidebar-bottom');
        document.body.classList.remove('sidebar-fixed');
      } else if (scrollTop > (contentTop - sidebarTopAmount)) {
        $('body').addClass('sidebar-fixed');
        document.body.classList.add('sidebar-fixed');
        document.body.classList.remove('sidebar-bottom');
      } else {
        document.body.classList.remove('sidebar-fixed');
        document.body.classList.remove('sidebar-bottom');
      }
    }

    /**
    * Go inside if-loop only if we're on a Oppaat template page
    */

    if (document.body.classList.contains('post-template-template-oppaat')) {
      // Add scroll event listener
      window.addEventListener('scroll', function() {
        window.requestAnimationFrame(sidebarHandler);
      }, false);

      // Add resize function
      $(window).resize(function() {
        contentTop = $('.single-post__content').offset().top;
        contentHeight = $('.single-post__content').outerHeight();
        windowHeight = $(window).height();
        documentHeight = $(document).height();
        sidebarHeight = $('.single-post__sidebar__bg').outerHeight();
        sidebarHandler();
      });

      sidebarHandler();
    }

    /**
     * Oppaat-page: show/hide sidebar's navi child elements
     */

    $('.sidebar-toggle').on('click', function() {
      $(this).parent().toggleClass('open');
      sidebarHeight = $('.single-post__sidebar__bg').outerHeight();
      sidebarHandler();
    });

    /**
     * Oppaat-page: show/hide sidebar on mobile screens
     */

    $('#btn-sidebar-toggle').on('click', function() {
      $(this).parent().toggleClass('visible');
    });

    /**
     *  Oppaat-page: sidebar click animate scroll
     */

    $('.sidebar-link').on('click', function() {
      var urlHash = this.getAttribute('data-anchor');
      if (urlHash !== undefined) {
        $('html,body').animate({scrollTop: ($('#' + urlHash).offset().top - 70)}, 500);
      }
    });

    /**
     * Search form customize form action
     */

    $('#search-form .search-field').on('input', function() {
      $('#search-form').attr('action', '/wp-haku/' + encodeURIComponent($(this).val()));
    });

  });
})(jQuery);
