/*
 * Global modules
 */

const header = {};
const navigation = {};

/**
 * Sticky element handling
 */
(function() {

  const STICKY_CSS_STICKY = 'header--is-sticky';
  const STICKY_CSS_SCROLLING_DOWN = 'header--scrolling-down';
  const STICKY_CSS_SCROLLING_UP = 'header--scrolling-up';

  // get the sticky element
  const headerElement = document.querySelector('.js-sticky-header');
  // then record the current position, so when we cross the
  // boundary the `sticky` class can be toggled
  let boundary = 0;

  if (headerElement) {
    boundary = headerElement.offsetTop + headerElement.scrollHeight;
  }

  const delta = 30;
  let lastScrollTop = 0;
  let isSticky = false;

  /**
   * Sets the CSS classes on scrolling event
   */
  const stickyHandler = function() {
    // collect current scroll position
    const y = window.pageYOffset;

    /*
     * First check the sticky situation
     */

    if (y > boundary) {
      if (!isSticky) {
        headerElement.classList.add(STICKY_CSS_STICKY);
        isSticky = true;
      }
    } else {
      headerElement.classList.remove(STICKY_CSS_STICKY);
      headerElement.classList.remove(STICKY_CSS_SCROLLING_UP);
      headerElement.classList.remove(STICKY_CSS_SCROLLING_DOWN);
      isSticky = false;

      return; // Rest of the function becomes useless

    }

    /*
     * Then check which direction the scrolling happens
     */

    // Scrolling much?
    if (Math.abs(lastScrollTop - y) <= delta) {
      return;
    }

    if (y > lastScrollTop && isSticky) {
      // Scrolling down
      if (header.isOpen) {
        header.collapseHeader();
      }

      headerElement.classList.add(STICKY_CSS_SCROLLING_DOWN);
      document.body.classList.remove('body--scrolling-up');
      headerElement.classList.remove(STICKY_CSS_SCROLLING_UP);

    } else {
      // Scrolling up
      if (y + window.innerHeight < document.body.scrollHeight) {
        headerElement.classList.remove(STICKY_CSS_SCROLLING_DOWN);
        headerElement.classList.add(STICKY_CSS_SCROLLING_UP);
        document.body.classList.add('body--scrolling-up');
      }
    }

    lastScrollTop = y;

  };

  window.addEventListener('scroll', function() {
    window.requestAnimationFrame(stickyHandler);
  }, false);

}());

/**
 * The header expand / collapse
 */
(function(header) {
  'use strict';

  const headerElement = document.querySelector('.js-expanding-header');
  const headerContainer = document.querySelector('.header__container');

  /**
   * CSS handling of the expanding
   */
  function expandHeader() {
    if (navigation.isOpen) {
      navigation.closeMobileMenu();
    }

    headerContainer.classList.add('header__container--large');
    headerElement.classList.add('header--is-open');
  }

  /**
   * Handling header expanding
   * @param {Event} event
   * @returns {boolean}
   */
  function expandHeaderEventHandler(event) {
    if (event) {
      event.preventDefault();
    }

    expandHeader();
    header.isOpen = true;

    return false;

  }

  /**
   * CSS handling on collapse
   */
  function collapseHeader() {
    headerContainer.classList.remove('header__container--large');
    headerElement.classList.remove('header--is-open');
  }

  /**
   * Handling the collapse of the header
   * @param {Event} event
   * @returns {boolean}
   */
  function collapseHeaderEventHandler(event) {
    if (event) {
      event.preventDefault();
    }

    collapseHeader();
    header.isOpen = false;

    return false;

  }

  function onNavigationOpen() {
    collapseHeader();
    headerElement.classList.add('header--navigation-open');
  }

  function onNavigationClose() {
    headerElement.classList.remove('header--navigation-open');
  }

  /**
   * Collapse header when clicked outside.
   */
  document.addEventListener('click', function(event) {

    if (!header.isOpen || !headerContainer) {
      return;
    }

    const isClickInside = headerContainer.contains(event.target);

    if (!isClickInside) {
      header.collapseHeader();
    }
  });

  const headerExpand = document.querySelector('.js-header-expand');
  if (headerExpand) {
    headerExpand.addEventListener('click', expandHeaderEventHandler, false);
  }

  const headerCollapse = document.querySelector('.js-header-collapse');
  if (headerCollapse) {
    headerCollapse.addEventListener('click', collapseHeaderEventHandler, false);
  }

  // Export
  header.collapseHeader = collapseHeader;
  header.onNavigationOpen = onNavigationOpen;
  header.onNavigationClose = onNavigationClose;
  header.isOpen = false;

})(header);

/**
 * Mobile navigation handling
 */
(function(navigation) {

  const mobileMenu = document.querySelector('.js-mobile-menu');
  const mobileMenuOpener = document.querySelector('.js-open-mobile-menu');
  const mobileMenuCloser = document.querySelector('.js-close-mobile-menu');

  function openMobileMenu() {
    if (navigation.isOpen) {
      return;
    }

    header.onNavigationOpen();
    mobileMenuCloser.classList.remove('hidden');
    mobileMenuOpener.classList.add('hidden');
    mobileMenu.classList.remove('navigation__mobile--hidden');
    navigation.isOpen = true;
  }

  function closeMobileMenu() {
    if (!navigation.isOpen) {
      return;
    }

    header.onNavigationClose();
    mobileMenuCloser.classList.add('hidden');
    mobileMenuOpener.classList.remove('hidden');
    mobileMenu.classList.add('navigation__mobile--hidden');
    navigation.isOpen = false;
  }

  if (mobileMenuOpener) {
    mobileMenuOpener.addEventListener('click', function(event) {
      if (event) {
        event.preventDefault();
      }

      openMobileMenu();
    }, false);

    mobileMenuCloser.addEventListener('click', function(event) {
      if (event) {
        event.preventDefault();
      }

      closeMobileMenu();
    }, false);
  }

  navigation.isOpen = false;
  navigation.closeMobileMenu = closeMobileMenu;

})(navigation);
